<template>
  <div
    :class="{
      stu_score: true,
      youxiu: value >= 90,
      zhongdeng: value > 60 && value < 90,
      yiban: value <= 60,
    }"
  >
    <h3>{{ title }}</h3>
    <div class="scores">
      <span>周考:{{ val }}</span>
      <br />
      <span>补考:{{ bukao }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "StuScore",
  data() {
    return {
      read: false,
      value: this.val,
      t_id: this.id,
    };
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    val: {
      type: String,
      default: "0",
    },
    bukao: {
      type: String,
      default: "0",
    },
  },
};
</script>
<style lang="less" scoped>
.stu_score {
  width: 100px;
  position: relative;
  background: red;
  padding: 5px 10px;
  margin: 20px;
  color: white;
  border-radius: 5px;
  float: left;
  cursor: pointer;
  h3 {
    font-size: 14px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // text-align: center;
  }
}
.youxiu {
  background-color: #f4f4f4;
  color: #555;
  border: 1px solid #555;
}
.zhongdeng {
  background-color: #409eff;
  border: 1px solid #409eff;
}
.yiban {
  background-color: #f56c6c;
  border: 1px solid #f56c6c;
}
</style>
