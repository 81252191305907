<template>
  <ul class="fenxi">
    <li class="kind1">总次数:<br />{{ fenxi.totle }}</li>
    <li class="kind1">优秀次数:<br />{{ fenxi.youxiu }}</li>
    <li class="kind1">
      优秀率:<br />{{ ((fenxi.youxiu * 100) / fenxi.totle).toFixed(2) }}%
    </li>
    <li class="kind2">及格次数:<br />{{ fenxi.jige }}</li>
    <li class="kind2">
      及格率:<br />{{ ((fenxi.jige * 100) / fenxi.totle).toFixed(2) }}%
    </li>
    <li class="kind3">不及格次数:<br />{{ fenxi.bujige }}</li>
    <li class="kind3">
      不及格率:<br />{{ ((fenxi.bujige * 100) / fenxi.totle).toFixed(2) }}%
    </li>
  </ul>
</template>
<script>
export default {
  name: "Fenxi",
  props: ["fenxi"],
};
</script>
<style lang="less" scoped>
.fenxi {
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  color: #555;
  width: 95%;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
  li {
    padding: 0 15px;
    font-size: 14px;
  }
}
.kind1 {
  border-left: 2px solid #409eff;
}
.kind2 {
  border-left: 2px solid #67c23a;
}
.kind3 {
  border-left: 2px solid #e6a23c;
}
.zkName {
  padding: 10px;
  color: #555;
}
</style>
